import type { Nullable } from '@models/CustomUtilityTypes';
import type { WhlModuleType } from '@models/WhlModuleType';

export default (): Ref<Nullable<WhlModuleType>> => {
  const route = useRoute();

  return computed((): Nullable<WhlModuleType> => {
    if (route.meta?.whlModuleType) {
      return route.meta.whlModuleType as WhlModuleType;
    }
    return null;
  });
};
